import React from "react"
import {
  Flex,
  Box,
  Heading,
  Text,
  UnorderedList,
  ListItem,
  chakra,
} from "@chakra-ui/react"
import { StaticImage } from "gatsby-plugin-image"

const styles = {
  root: {
    width: "100%",
    px: { base: "16px", md: "24px", lg: "24px" },
  },
  "inner-box": {
    marginX: "auto",
    my: { base: "20px", md: "20px", lg: "60px" },
    maxWidth: "812px",
  },
  "top-section": {
    flexDirection: "column",
    alignItems: "center",
  },
  heading: {
    fontSize: "32px",
    fontWeight: 700,
    lineHeight: "40px",
    textAlign: "center",
    letterSpacing: "0em",
  },
  "allstar-text": {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#129459",
  },
  text: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0em",
  },
  "list-heading": {
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    letterSpacing: "0em",
  },
  hero: {
    mt: { base: "16px", md: "16px", lg: "24px" },
    width: { base: "144px", md: "144px", lg: "160px" },
  },
  span: {
    fontWeight: "bold",
  },
}

const SectionBody = () => (
  <Box sx={styles.root}>
    <Box sx={styles["inner-box"]}>
      <Flex sx={styles["top-section"]}>
        <Heading as="h2" sx={styles["allstar-text"]}>
          Healthcare Research All-Stars
        </Heading>
        <Heading as="h2" sx={styles.heading} mt="8px">
          Key Findings
        </Heading>
        <Box sx={styles.hero} mb="40px">
          <StaticImage
            src="../../../images/allstar/Healthcare Research All-Stars 2024 - Logo.png"
            alt="Healthcare Research All-Stars Logo"
            placeholder="blurred"
          />
        </Box>
      </Flex>
      <Box sx={{ mt: "4px" }}>
        <Text sx={styles.text}>
          Utilizing national physician data, Medicare fee-for-service data, and
          PubMed data, the Healthcare Research All-Stars initiative identified
          the top 5% of physician researchers across a wide range of surgical
          specialties including cardiology, cardiothoracic surgery, vascular
          surgery, otolaryngology (ENT), gastroenterology, neurosurgery,
          orthopedic surgery, spine surgery, urology, surgical oncology, and
          general surgery. More than 4,500 physicians and 350 hospitals were
          honored out of a pool of more than 90,000 physicians and 4,000
          hospitals.{" "}
        </Text>
        <Text sx={{ mt: "24px" }}></Text>
        <Text sx={styles.text}>
          An analysis of 1.8 million procedures shows that top researchers
          consistently achieve better patient outcomes compared to their peers.
          This analysis is based on a sophisticated model that controls for
          patient demographics, comorbidity score, surgeon characteristics,
          hospital characteristics, and care settings.
        </Text>{" "}
        <UnorderedList sx={{ px: "24px", mt: "20px" }}>
          <ListItem sx={styles.text}>
            <chakra.span sx={styles.span}>
              Reduced Complication Rates:
            </chakra.span>
            Patients under the care of All-Star researchers experienced a 5%
            reduction in the rate of post-discharge complications.
          </ListItem>
          <ListItem>
            <chakra.span sx={styles.span}>Lower Mortality Rates:</chakra.span>
            There was a 5% reduction in the rate of mortality within 30 days
            post-discharge among patients treated by these top researchers.
          </ListItem>
        </UnorderedList>
      </Box>

      <Box sx={{ mt: "32px" }}>
        <Text sx={styles["list-heading"]}>
          Insights on Authorship, Volume, and Quality
        </Text>
        <Text sx={styles.text} mt="4px">
          Studies conducted by top surgeon researchers collectively showcase a
          remarkable output, with an average publication rate of 11 articles per
          year. This contrasts sharply with the broader group of surgeons, whose
          average stands at just 0.5 articles annually. The quality of these
          publications is notable, with the work of top surgeon researchers
          appearing in medical journals that have an average impact factor of
          4.7, in comparison to 2.9 for journals featuring the work of the
          broader group of surgeons. A significant portion of top surgeon
          researchers, 47.1% on average, assume leadership roles in their
          research, serving as the leading or senior authors. This collective
          effort and leadership in research underlines their pivotal role in
          driving medical innovations and enhancing patient care practices.
        </Text>
      </Box>
      <Box sx={{ mt: "32px" }}>
        <Text sx={styles["list-heading"]}>
          Broadening Academic Collaboration
        </Text>
        <Text sx={styles.text} mt="4px">
          Top-tier surgeons also exhibit broader academic collaboration, working
          with a more diverse group of institutions, which may contribute to the
          richness of their research and its applicability across different
          clinical settings.
        </Text>
      </Box>
      <Box sx={{ mt: "32px" }}>
        <Text sx={styles["list-heading"]}>Why This Matters</Text>
        <Text sx={styles.text} mt="4px">
          These insights underscore the significant role of research in driving
          advancements in healthcare. The initiative not only shines a spotlight
          on the individual achievements of surgeons but also emphasizes the
          importance of supporting healthcare research. Through dedicated
          research, these physicians are setting new standards for patient care,
          affirming that progress in medical research directly translates into
          better patient outcomes.
        </Text>
      </Box>
    </Box>
  </Box>
)

export default SectionBody
