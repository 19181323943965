import React from "react"

import Layout from "../components/allstar/layout"

import SectionBody from "../components/allstar/key-findings/section-body"

const KeyFindingsPage = props => {
  return (
    <Layout location={props.location} title={"Key Findings"}>
      <SectionBody />
    </Layout>
  )
}

export default KeyFindingsPage
